import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Projects",
  heading = <>My  <span tw="text-primary-500">Project.</span></>,
  description = "Some of the projects I have created are projects that I have worked on both individually and in teams and use several frameworks in them",

}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1ZSqiM_kBwB4MlroNpitnw-mlFMPoOWZ9=w2000-h750-iv1",
      author: "Pusdatin Kemsos",
      category: "Fullstack (2018)",
      title: "Information System PSKS",
      description: "PSKS is an information system used by the Ministry of Social Affairs to collect data on the community environment.",
      url: "https://github.com/vihermawan/psks-magang"
    },
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1GCFEtXJ5uEXoQn9_lD5NQzc4RuWm1oeL=w1920-h990-iv1",
      author: "DEB UGM",
      category: "Frontend (2018)",
      title: "Information System Kopaja(Kompas Pajak Ajaib)",
      description: "The Kopaja Information System is an information system for learning facilities for high school students regarding taxation, contains material and practice exercises about taxes in it.",
      url: "https://kopaja.id/"
    },
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1_mj_xyJzh7q3YOtGA-I9z-ny-AVQHjTF=w2000-h750-iv1",
      author: "DEB UGM",
      category: "Frontend (2019)",
      title: "Menara Ilmu DEB",
      description: "The science tower is a learning tool from the UGM website channel, containing material on taxation and some information about taxes.",
      url: "https://perpajakan.sv.ugm.ac.id/"
    },
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1C_HrtwmbKum2krjbNlU_EQL_CcscpXCM=w2000-h464-iv1",
      author: "Final Project",
      category: "Fullstack (2020)",
      title: "EventIn",
      description: "EventIn is a website that is used for my final project and collaborates with Gamatehcno for work and development.",
      url: "https://eventin-dev.herokuapp.com"
    },
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1H8HcsUH1xSAkwS6UJL--WzIbJyqqmuuW=w1920-h990-iv1",
      author: "Freelance",
      category: "Backend (2020)",
      title: "Public Safety Center",
      description: "The public safety center is a system that connects the public with the hospital, the community can make reports directly in case of an accident and will be directly connected to the hospital and officers will immediately come to the scene.",
      url: "http://psc.mlsn.tech/login"
    },
    {
      imageSrc:
        "https://lh3.google.com/u/0/d/1wuIQhVXEwiNI7k2rI_0gte0ndXC7_241=w2000-h464-iv1",
      author: "BroilerX",
      category: "Fullstack (2020)",
      title: "Saas BroilerX",
      description: "BroilerX is a startup where I am now working as part time, here I am working on a website for data management for livestock cages for farmers.",
      url: "https://saas.broilerx.com"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Link href={post.url}>Show Project</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
