import React from "react";
import tw from "twin.macro";

import LogoImage from "../../images/icon.png";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Vihermawan.</LogoText>
          </LogoContainer>
          <CompanyDescription>
          This website is a portofolio of Vicky Hermawan to show of projects and skills.
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Social Media</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="https://www.linkedin.com/in/vicky-hermawan/">LinkedIn</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://www.instagram.com/vihermawan/">Instagram</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="https://twitter.com/vihermawan">Twitter</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Service</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Fullstack</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Restful API</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Frontend</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">UI/UX</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
