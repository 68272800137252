import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithVideo";
import Tools from "components/features/TwoColWithTwoFeaturesAndButtons";
import Project from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Contact from "components/cta/GetStarted";
import Footer from "components/footers/SimpleFiveColumn"

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Tools />
      <Project />
      <Contact />
      <Footer />
    </AnimationRevealPage>
  );
};
